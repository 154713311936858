<template>
  <v-row
    no-gutter
    dense
    class="delivery-zone R-Layout d-flex flex-lg-column flex-md-row"
  >
    <div class="d-flex justify-start pb-10" style="margin-top: -40px">
      <div v-on:click="changeTab('authorize')">
        <app-back-btn />
      </div>
    </div>

    <v-row>
      <v-col cols="12" sm="8">
        <v-card tag="div" elevation="0" outlined color="#F7F9FC">
          <p class="my-heading ma-0 pb-3">Add delivery zone</p>
          <p class="simple-text" style="padding-bottom: 36px">
            Please create up to 5 delivery zones by identifying shipping costs
            based on the distance from your location.
          </p>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-row>
              <transition-group name="add-remove" tag="div" class="zone-width">
                <div
                  v-for="delivery in delivery_data"
                  v-bind:key="delivery.zone_name"
                  class="pr-sm-6 pb-6 add-remove-item"
                >
                  <app-delivery-zone :value="delivery">
                    <div slot="delivery-zone-delete">
                      <div v-if="delivery.zone_name != 'Zone 1'">
                        <img
                          :src="$store.state.icons.icons['Dustbin']"
                          @click="deleteZone(delivery)"
                          class="H-18 text-right"
                          alt
                        />
                      </div>
                    </div>
                  </app-delivery-zone>
                </div>
              </transition-group>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      dense
      v-if="$store.state.auth.error != ''"
      class="align-center justify-center p-0 custom-error"
    >
      <v-col cols="12">{{ this.$store.state.auth.error }}</v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-sm-row flex-column align-self-end justify-end pb-0" cols>
        <v-btn
          v-if="this.deliverys.length < 5"
          class="my-outline-btn mr-auto mr-5 mt-sm-0 mt-3"
          depressed
          outlined
          @click="addZone"
          >Add delivery zone</v-btn
        >

        <v-btn
          class="my-outline-btn mr-5 mt-sm-0 mt-3"
          depressed
          outlined
          @click="skipSettings"
          >Skip settings</v-btn
        >

        <v-btn
          class="my-p-btn my_elevation mt-sm-0 mt-3"
          id="my_elevation"
          depressed
          @click="next"
          :disabled="!valid"
          >Next</v-btn
        >
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
const DeliveryZone = () => import("../../mycomponents/Deliveryzone");
import {
  skipSettings,
  setUser,
  deliveryZoneStore,
} from "../../services/registerservice";

export default {
  components: {
    AppDeliveryZone: DeliveryZone,
  },
  data() {
    return {
      show: true,
      valid: true,
      error_message: "",
      show_message: "none",
      user_id: "",
      company_id: "",
      deliverys: [],
    };
  },

  async created() {
    if (
      this.$store.state.register.register_user.user_id === "" ||
      this.$store.state.register.company.company_id === ""
    ) {
      await setUser();
      this.myDelivery();
    } else {
      this.myDelivery();
    }
  },

  beforeRouteLeave(to, from, next) {
    this.storeVuex();
    next();
  },

  computed: {
    Show() {
      return (this.show = !this.valid ? false : true);
    },

    ShowMessage() {
      return (this.show_message = !this.error_message ? "none" : "block");
    },

    delivery_data() {
      this.deliverys.forEach((item, index) => {
        item.zone_name = "Zone " + (index + 1);
      });

      return this.deliverys;
    },
  },

  methods: {
    myDelivery() {
      let user = JSON.parse(
        JSON.stringify(this.$store.state.register.register_user)
      );

      let company = JSON.parse(
        JSON.stringify(this.$store.state.register.company)
      );

      let delivery = JSON.parse(
        JSON.stringify(this.$store.state.register.delivery_zone)
      );

      this.user_id = user.user_id;
      this.company_id = company.company_id;

      if (delivery.length > 0) {
        delivery.forEach((item) => {
          let count = this.deliverys.length + 1;
          let sd;
          if (item.distance === 5) {
            sd = 0;
          } else if (item.distance === 15) {
            sd = 1;
          } else if (item.distance === 25) {
            sd = 2;
          } else if (item.distance === 45) {
            sd = 3;
          } else if (item.distance === 75) {
            sd = 4;
          }

          var obj = {
            zone_id: item.zone_id,
            zone_name: item.zone_name,
            distance: item.distance,
            distance_select: sd,
            shipping_price: item.shipping_price,
          };
          this.deliverys.push(obj);
        });
      } else {
        let count = this.deliverys.length + 1;
        var obj = {
          zone_id: 0,
          zone_name: "Zone " + count,
          distance: "",
          distance_select: 0,
          shipping_price: 200,
        };
        this.deliverys.push(obj);
      }
    },

    addZone() {
      let count = this.deliverys.length + 1;
      var obj = {
        zone_id: 0,
        zone_name: "Zone " + count,
        distance: "",
        distance_select: 0,
        shipping_price: 0,
      };
      this.deliverys.push(obj);
      this.$store.commit("deliverycomplete");
      this.$store.state.register.delivery_zone.push(
        JSON.parse(JSON.stringify(obj))
      );
    },

    deleteZone(delivery) {
      this.deliverys.splice(this.deliverys.indexOf(delivery), 1);
      this.$store.state.register.delivery_zone.splice(
        this.deliverys.indexOf(delivery),
        1
      );
      this.$store.commit("deliverycomplete");
    },

    storeVuex() {
      this.$store.commit(
        "registerDeliveryZone",
        JSON.parse(JSON.stringify(this.deliverys))
      );
    },

    changeTab(tab_name) {
      this.errorMessages = "";
      this.$store.commit("errorMessage", "");
      this.$store.commit("registerTab", tab_name);
    },

    async next() {
      if (this.$refs.form.validate()) {
        this.$store.commit("errorMessage", "");
        this.deliverys.forEach((item) => {
          if (item.distance_select === 0) {
            item.distance = 5;
          } else if (item.distance_select === 1) {
            item.distance = 15;
          } else if (item.distance_select === 2) {
            item.distance = 25;
          } else if (item.distance_select === 3) {
            item.distance = 45;
          } else if (item.distance_select === 4) {
            item.distance = 75;
          }
        });

        let distance = [5, 15, 25, 45, 75];
        distance.forEach((item) => {
          let data = this.deliverys.filter((delivery) => {
            if (delivery.distance === item) {
              return delivery;
            }
          });

          if (data.length > 1) {
            let message =
              "Your delivery zone " +
              data[0].zone_name +
              " and " +
              data[1].zone_name +
              " distance is overlapped";
            this.$store.commit("errorMessage", message);
          }
        });

        if (this.$store.state.auth.error === "") {
          let data = await deliveryZoneStore(this.user_id, this.company_id, {
            deliverys: this.deliverys,
          });

          if (data.data) {
            skipSettings();
          }
        }
      }
    },

    skipSettings() {
      skipSettings();
    },
  },
};
</script>

<style lang="scss">
.zone-width {
  width: auto;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}
</style>